<template>
  <div class="contact">
    <div class="joinWrap">
      <div class="wrap">
        <div class="title">联系我们</div>
        <div class="line"></div>
        <div class="info">
          <div class="item">
            <img :src="`img/icon2.png`" alt="" />
            <div class="txt">电话：+86 166 5115 5567</div>
          </div>
          <div class="item">
            <img :src="`img/icon3.png`" alt="" />
            <div class="txt">邮箱：demon.nie@comeffie.com</div>
          </div>
          <div class="item">
            <img :src="`img/icon1.png`" alt="" />
            <div class="txt">
              地址：安徽省合肥市天鹅湖路蔚蓝商务港D座1710
            </div>
          </div>
        </div>
        <div class="logoFix"></div>
      </div>
    </div>
    <div class="aboutImg">
      <div class="wrap">
        <div class="title">JOIN US</div>
        <div class="info">
          成为康斐科技的一员，这里有优美的环境，<br />
          Nice的团队，这里使你成长。
        </div>
        <router-link to="/join" class="arrow"
          ><img :src="`img/arrow.png`" alt=""
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped  rel="stylesheet/stylus">
.contact {
  background-color: #F1F1F1;
  padding-top: 77px;
}

.joinWrap {
  width: 1240px;
  margin: 0 auto 100px;
  height: 419px;
  position: relative;
  background: url('../../public/img/conBj.png') no-repeat right top;
  background-size: 722px 419px;

  .logoFix {
    position: absolute;
    right: 293px;
    top: 122px;
    width: 99px;
    height: 49px;
    background: url('../../public/img/logoFix.png') no-repeat center top;
    background-size: 99px 49px;
  }

  .wrap {
    width: 536px;
    height: 419px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 70px 0 0 35px;
  }

  .title {
    font-size: 36px;
    font-family: MicrosoftYaHei-Bold;
    font-weight: bold;
    color: #323232;
  }

  .line {
    width: 43px;
    height: 3px;
    background: #1B1B1B;
    margin: 25px 0 55px;
  }

  .info {
    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 50px;

      img {
        width: 19px;
        height: 19px;
        margin-right: 20px;
      }

      .txt {
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #1B1B1B;
        line-height: 20px;
      }
    }
  }
}

.aboutImg {
  width: 1240px;
  margin: 0 auto 100px;
  height: 419px;
  position: relative;
  background: url('../../public/img/about.png') no-repeat center top;
  background-size: 1240px 419px;
  padding-bottom: 110px;

  .wrap {
    width: 536px;
    height: 419px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 70px 0 0 35px;
  }

  .title {
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #323232;
    padding-bottom: 23px;
  }

  .info {
    font-size: 23px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #323232;
    line-height: 35px;
    padding-bottom: 130px;
  }

  .arrow {
    display: block;

    img {
      width: 58px;
      height: 58px;
    }
  }
}
</style>